import {React,useState,useEffect} from 'react'
import './delivery.css'
import Topbar from '../../components/topbar/Topbar'
import {useLocation} from 'react-router-dom';
import Map from '../Map';
import Axios from 'axios'

export default function Delivery() {
    const location = useLocation();
    const[thisOrder, setThisOrder] = useState([]);
    const[customerName,setCustomerName] = useState('')
    const[customerNumber, setCustomerNumber] = useState('')
    const[store,setStore] = useState('')
    const[area,setArea] = useState('')
    const[landmark,setLandmark] = useState('')
    const[orderDesc,setOrderDesc] = useState('')
    const[orderValue,setOrderValue] = useState('')
    const[instructions, setInstructions] = useState('')
    const[status,setStatus] = useState('')


    var orderNo = location.state.order
    
    useEffect(() =>{
        Axios.get('https://adminapi.albatroxlogistics.com/api/getThisOrder?orderNo='+orderNo).then((res)=>{
                setStore(res.data.store)
                setCustomerName(res.data.customerName)
                setCustomerNumber(res.data.customerContact)
                setArea(res.data.area)
                setLandmark(res.data.landmark)
                setOrderDesc(res.data.orderDesc)
                setOrderValue(res.data.orderValue)
                setInstructions(res.data.instructions)
                setStatus(res.data.status)


            })
       
      },[orderNo])
      var call = 'tel:'+customerNumber;
  return (
    <div className='deliveryWrapper'>
        <div className="bigScreen">
        <p>Version only available in mobile screen</p>
        </div>
        <div className="mobileScreen">
            <Topbar />
            <div className="orderDet">
                <div className="orderDetLeft">
                    <p className='ordersP'><strong>orderNo:</strong> {location.state.order}</p>
                    <p className='ordersP'><strong>Store:</strong>{store}</p>
                    <p className='ordersP'><strong>Customer:</strong> {customerName}</p>
                    <p className='ordersP'><strong>Contacts:</strong> {customerNumber}</p>
                    <p className='ordersP'><strong>Order Value:</strong> {orderValue}</p>
                    <p className='ordersP'><strong>Instructions:</strong> {instructions}</p>
                </div>
                <div className="orderDetRight">
                    <p className='ordersP'><strong>Description:</strong> {orderDesc}</p>
                    <p className='ordersP'><strong>Location:</strong> {area}</p>
                    <p className='ordersP'><strong>Landmark:</strong> {landmark}</p>
                    <p className='ordersP'><strong>Status:</strong> {status}</p>

                    


                </div>

                
                

            </div>
            <a  className='call' href={call} >Call Customer</a>
            
            
            <Map start={store} loc={area} orderNo={location.state.order} status={status} />
            
            
            
        </div>

    </div>
  )
}
